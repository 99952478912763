import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

function LicenseForm({ licenseData, setLicenseData, handleSubmit, setShowForm, isEdit, initialData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLicenseData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleMetaChange = (index, key, value) => {
    const updatedMeta = [...(licenseData.meta || [])];
    updatedMeta[index] = { ...updatedMeta[index], [key]: value };
    setLicenseData(prevData => ({ ...prevData, meta: updatedMeta }));
  };

  const addMetaField = () => {
    setLicenseData(prevData => ({ ...prevData, meta: [...(prevData.meta || []), { key: '', value: '' }] }));
  };

  const displayChanges = () => {
    const changes = [];
    if (licenseData.customerId !== initialData.customerId) changes.push(`Customer ID: ${licenseData.customerId}`);
    if (licenseData.product !== initialData.product) changes.push(`Product: ${licenseData.product}`);
    if (licenseData.variant !== initialData.variant) changes.push(`Variant: ${licenseData.variant}`);
    if (licenseData.version !== initialData.version) changes.push(`Version: ${licenseData.version}`);
    if (licenseData.count !== initialData.count) changes.push(`Count: ${licenseData.count}`);
    if (licenseData.startDate
        && (licenseData.startDate.toString() !== (initialData.startDate ? initialData.startDate.toString() : ''))
    ) {
        changes.push(`Start Date: ${licenseData.startDate?.toString()}`);
    }
    if (licenseData.endDate
        && (licenseData.endDate.toString() !== (initialData.endDate ? initialData.endDate.toString() : ''))
    ) {
        changes.push(`End Date: ${licenseData.endDate?.toString()}`);
    }
    if (licenseData.status !== initialData.status) changes.push(`Status: ${licenseData.status}`);
    (licenseData.meta || []).forEach((meta, index) => {
      const initialMeta = initialData.meta?.[index] || {};
      if (meta.key !== initialMeta.key || meta.value !== initialMeta.value) {
        changes.push(`Meta [${meta.key}]: ${meta.value}`);
      }
    });
    return changes;
  };

  return (
    <div className="bg-light p-3 mb-4">
      <h4 className="mb-3 text-left">{isEdit ? 'Edit License' : 'Add New License'}</h4>
      <form onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="customerId" className="form-label">Customer ID</label>
            <input type="text" className="form-control" id="customerId" name="customerId" placeholder="great-customer-1" value={licenseData.customerId} onChange={handleChange} disabled={isEdit} />
          </div>
          <div className="col-md-6">
            <label htmlFor="product" className="form-label">Product</label>
            <input type="text" className="form-control" id="product" name="product" placeholder="compiler" value={licenseData.product} onChange={handleChange} disabled={isEdit} />
          </div>
          <div className="col-md-6">
            <label htmlFor="variant" className="form-label">Variant</label>
            <input type="text" className="form-control" id="variant" name="variant" placeholder="arm" value={licenseData.variant} onChange={handleChange} disabled={isEdit} />
          </div>
          <div className="col-md-6">
            <label htmlFor="version" className="form-label">Version</label>
            <input type="text" className="form-control" id="version" name="version" placeholder="5.4.3" value={licenseData.version} onChange={handleChange} disabled={isEdit} />
          </div>
          <div className="col-6">
            <label htmlFor="count" className="form-label">Count</label>
            <input type="number" className="form-control" id="count" name="count" placeholder="0" value={licenseData.count} onChange={handleChange} />
          </div>
          <div className="col-6">
            <label htmlFor="startDate" className="form-label">Start Date</label>
            <Datetime
              value={licenseData.startDate}
              onChange={date => setLicenseData(prevData => ({ ...prevData, startDate: date }))}
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
            />
          </div>
          <div className="col-6">
            <label htmlFor="status" className="form-label">Status</label>
            <select className="form-control" id="status" name="status" value={licenseData.status} onChange={handleChange}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="endDate" className="form-label">End Date</label>
            <Datetime
              value={licenseData.endDate}
              onChange={date => setLicenseData(prevData => ({ ...prevData, endDate: date }))}
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
            />
          </div>
          <div className="col-12">
            <label className="form-label">Meta Attributes</label>
            {(licenseData.meta || []).map((meta, index) => (
              <div key={index} className="row mb-2">
                <div className="col-5">
                  <input type="text" className="form-control" placeholder="Key" value={meta.key} onChange={e => handleMetaChange(index, 'key', e.target.value)} />
                </div>
                <div className="col-5">
                  <input type="text" className="form-control" placeholder="Value" value={meta.value} onChange={e => handleMetaChange(index, 'value', e.target.value)} />
                </div>
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={addMetaField}>Add Meta</button>
          </div>
          <div className="col-12">
            <p>Changes to be made:</p>
            {displayChanges().map((change, index) => (
              <p key={index}>{change}</p>
            ))}
          </div>
          <div className="col-12">
            <button type="button" className="btn btn-secondary me-2" onClick={() => setShowForm(false)}>Cancel</button>
            <button type="submit" className="btn btn-success">{isEdit ? 'Update' : 'Add New'}</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LicenseForm;
