import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CustomerForm from './CustomerForm';
import CustomerTable from './CustomerTable';
import getApiBaseUrl from './api';

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [selected, setSelected] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [username, setUsername] = useState('');

  const EMPTY_STATE = {
    customerId: '',
    password: ''
  };
  const [customerData, setCustomerData] = useState(EMPTY_STATE);

  const [filters, setFilters] = useState({
    customerId: '',
  });

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    } else {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUsername(decodedToken.username);
      fetchData();
    }
  }, [history]);

  useEffect(() => {
    const filter = () => {
      setFiltered(customers.filter((customer) =>
        Object.keys(filters).every((key) =>
          filters[key] === '' || customer[key]?.toString().includes(filters[key])
        )
      ));
    };

    filter();
  }, [customers, filters]);

  const handleGoToDashboard = async (e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
  };

  const genericErrorHandler = (error, alertMessage) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Unauthorized. Please login again.');
      history.push('/login');
    } else {
      if (error.response && error.response.data.message) {
        alertMessage += "\n\n" + error.response.data.message;
      }
      alert(alertMessage);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${getApiBaseUrl()}/customers`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setCustomers(response.data);
    } catch (error) {
      genericErrorHandler(error, 'Failed to fetch customers.');
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${getApiBaseUrl()}/customers`, customerData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setShowAdd(false);
      setCustomerData(EMPTY_STATE);
      await fetchData().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to add customer.');
    }
  };

  const handleRemove = async (e, customerId) => {
    e.preventDefault();
    try {
      await axios.delete(`${getApiBaseUrl()}/customers/${customerId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      await fetchData().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove customer.');
    }
  };

  const handleRemoveSelected = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(selected.map(customer =>
        axios.delete(`${getApiBaseUrl()}/customers/${customer.customerId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      ));
      setCustomers([]);
      await fetchData().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove selected customers.');
    }
  };

  const handleEdit = (customer, index) => {
    setCustomerData({
      customerId: customer.customerId,
      password: '',
    });
    setEditIndex(index);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${getApiBaseUrl()}/customers`, customerData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setEditIndex(null);
      setCustomerData(EMPTY_STATE);
      await fetchData().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to update customer.');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSelect = (item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((l) => l !== item)
        : [...prevSelected, item]
    );
  };

  const handleSelectAll = () => {
    if (selected.length === filtered.length) {
      setSelected([]);
    } else {
      setSelected(filtered);
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Welcome {username} | IAR Customers: </h2>
        <button className={`btn btn-primary`} onClick={handleGoToDashboard}>
          Go to Licenses Dashboard
        </button>
        <button className={`btn btn-primary ${showAdd ? 'invisible' : ''}`} onClick={() => {
          setCustomerData(EMPTY_STATE);
          setShowAdd(!showAdd)
        }}>
          Add Customer
        </button>

      </div>
      {showAdd && (
        <CustomerForm
          customerData={customerData}
          setCustomerData={setCustomerData}
          handleSubmit={handleAdd}
          setShowForm={setShowAdd}
          isEdit={false}
        />
      )}
      {editIndex !== null && (
        <CustomerForm
          customerData={customerData}
          setCustomerData={setCustomerData}
          handleSubmit={handleUpdate}
          setShowForm={() => setEditIndex(null)}
          isEdit={true}
        />
      )}
      <div className="mb-3">
        <h4>Filter Customers</h4>
        <div className="d-flex">
          <input type="text" name="customerId" placeholder="Customer ID" value={filters.customerId} onChange={handleFilterChange} className="form-control mr-2" />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-danger" onClick={handleRemoveSelected} disabled={selected.length === 0}>
          Delete Selected
        </button>
        <span>{selected.length} selected</span>
      </div>
      <CustomerTable
        customers={filtered}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        selected={selected}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
      />
    </div>
  );
}

export default Customers;
