import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import getApiBaseUrl from './api';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${getApiBaseUrl()}/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      history.push('/dashboard');
    } catch (error) {
      alert('Login failed.');
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-center align-items-center mb-4">
        <h2>IAR Employee Login</h2>
      </div>
      <form onSubmit={handleLogin}>
        <div className="mb-3 w-50 mx-auto">
          <label htmlFor="username" className="form-label">Username</label>
          <input type="text" className="form-control" id="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="mb-3 w-50 mx-auto">
          <label htmlFor="password" className="form-label">Password</label>
          <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary">Login</button>
        </div>
      </form>
    </div>
  );
}

export default Login;
