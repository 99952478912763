import React from 'react';

function CustomerTable({ customers, handleEdit, handleRemove, selected, handleSelect, handleSelectAll }) {
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selected.length === customers.length && customers.length > 0}
            />
          </th>
          <th scope="col">Customer ID</th>
          <th scope="col" className="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                checked={selected.includes(customer)}
                onChange={() => handleSelect(customer)}
              />
            </td>
            <td>{customer.customerId}</td>
            <td className="text-end">
              <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(customer, index)}>Edit</button>
              <button className="btn btn-danger btn-sm" onClick={(e) => handleRemove(e, customer.customerId)}>Remove</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CustomerTable;
