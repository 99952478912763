import React from 'react';

function CustomerForm({ customerData, setCustomerData, handleSubmit, setShowForm, isEdit }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="bg-light p-3 mb-4">
      <h4 className="mb-3 text-left">{isEdit ? 'Edit Customer' : 'Add New Customer'}</h4>
      <form onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="customerId" className="form-label">Customer ID</label>
            <input type="text" className="form-control" id="customerId" name="customerId" placeholder="customer-1" value={customerData.customerId} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" className="form-control" id="password" name="password" placeholder="password" value={customerData.password} onChange={handleChange} />
          </div>
          <div className="col-12">
            <button type="button" className="btn btn-secondary me-2" onClick={() => setShowForm(false)}>Cancel</button>
            <button type="submit" className="btn btn-success">{isEdit ? 'Update' : 'Add New'}</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerForm;
