import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import Customers from './Customers';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/customers-dashboard" component={Customers} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
