// const getApiBaseUrl = () => {
//   const baseUrl = window.location.origin;
//   // return `${baseUrl}/api`;
//   return baseUrl;
// };

// export default getApiBaseUrl;

const getApiBaseUrl = () => {
  // return process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  return process.env.REACT_APP_API_BASE_URL || window.location.origin;
};

export default getApiBaseUrl;
